<template>
  <div class="contentBody" id="ysCont" @scroll="scrollEvent">
    <div class="contentBox">
      <div class="leftBox">
        <div class="userHead">
          <div class="img">
            <img :src="$imgUrl + info.avatar" alt="" />
          </div>
          <div class="cont">
            <div class="textBox">
              <div class="name">
                {{ info.name ? info.name : info.nickname }}
              </div>
            </div>
            <div class="dzBox">
              <div class="box">
                <div class="iconfont icon-coordinates_fill"></div>
                <div class="name">
                  {{ info.province }} {{ info.city }}
                  {{ info.area ? info.area : "暂无信息 " }}
                </div>
              </div>
              <div class="box">
                <div
                  class="iconfont icon-xinxipingtaiyeicons_qiuzuzhaozu"
                ></div>
                <div class="name">
                  {{ info.company_name ? info.company_name : "暂无信息" }}
                </div>
              </div>
            </div>
            <div class="jjTxt c-2">
              {{ info.signature ? info.signature : "暂无介绍~" }}
            </div>
          </div>
          <div class="gzBox">
            <div
              class="box"
              :class="info.is_favorite ? 'uns' : ''"
              v-if="member.id != this.id"
              @click="gzClick"
            >
              {{ info.is_favorite ? "取消关注" : "关注" }}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="leftcontHead">
            <div class="boxs">
              <div
                class="box"
                :class="index == typeOn ? 'on' : ''"
                v-for="(item, index) in typeList"
                @click="typeClick(index)"
              >
                <div class="name">{{ item }}</div>
              </div>
            </div>
            <div class="inpBox">
              <input
                type="text"
                placeholder="请搜索您发布的内容"
                v-model="seachName"
                @keyup.enter="searchClick()"
              />
              <div class="ssBox iconfont icon-search"></div>
            </div>
          </div>
          <div class="contList">
            <Kong v-if="member_forumList.length == 0" />
            <div
              class="cont"
              v-for="item in member_forumList"
              @click="ltjump(item.id)"
            >
              <div class="contHead">
                <div class="fbBox">
                  <div class="phoimg">
                    <img :src="$imgUrl + item.memberavatar" alt="" />
                  </div>
                  <div class="name">{{ item.membername }}</div>
                  <div class="txt">论坛</div>
                </div>
              </div>
              <div class="contTitle">
                {{ item.name }}
              </div>
              <div class="contText c-3">
                {{ item.content }}
              </div>
              <div class="funBox">
                <div class="boxs">
                  <div class="box">
                    <div class="iconfont icon-browse browse"></div>
                    <div class="num">{{ item.click }}</div>
                  </div>
                  <div class="box">
                    <div class="iconfont icon-message message"></div>
                    <div class="num">{{ item.childrenlength }}</div>
                  </div>
                  <div class="box">
                    <div class="iconfont icon-praise praise"></div>
                    <div class="num">{{ item.favoritelength }}</div>
                  </div>
                  <div class="box">
                    <div class="iconfont icon-send send"></div>
                    <div class="num">分享</div>
                  </div>
                </div>
                <div class="time">2024-06-18 11:58:36</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ritBox1">
        <div class="content">
          <div class="contHead">
            <div class="img">
              <div class="iconfont icon-collection_fill"></div>
            </div>
            <div class="name">社区联系</div>
          </div>
          <div class="cont">
            <div class="box" @click="lxJump(0)">
              <div class="num">{{ member_lengthData.guanzhu }}</div>
              <div class="name">关注</div>
            </div>
            <div class="box" @click="lxJump(1)">
              <div class="num">{{ member_lengthData.fensi }}</div>
              <div class="name">粉丝</div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="contHead">
            <div class="img huang">
              <div class="iconfont icon-new3-7qiuzu"></div>
            </div>
            <div class="name">社区成就</div>
          </div>
          <div class="cont">
            <div class="box">
              <div class="num">
                {{ member_lengthData.pinglun ? member_lengthData.pinglun : 0 }}
              </div>
              <div class="name">评论</div>
            </div>
            <div class="box">
              <div class="num">
                {{ member_lengthData.fabu ? member_lengthData.fabu : 0 }}
              </div>
              <div class="name">发布</div>
            </div>
            <div class="box">
              <div class="num">
                {{ member_lengthData.zan ? member_lengthData.zan : 0 }}
              </div>
              <div class="name">获赞与收藏</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Shezhi v-if="szshow" @show="shezhiShowJs" />
    <a-modal v-model="shouhuovisible" title="提示" @ok="shouhuohandleOk">
      <p>是否确认收货</p>
    </a-modal>
  </div>
</template>

<script>
import Shezhi from "../component/shezhi.vue";
import Kong from "../component/kong.vue";
import { Form, Space, Button, Modal } from "ant-design-vue";

export default {
  components: {
    Kong,
    Shezhi,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-button": Button,
    "a-space": Space,
    "a-modal": Modal,
  },
  name: "",
  data() {
    return {
      shouhuovisible: false,
      szshow: false,
      typeOn: 0,
      typeList: ["论坛列表"],
      info: {},
      addressInfo: {},
      member_lengthData: {
        orderinfo: {
          id: 0,
        },
      },
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      member_forumList: [],
      member: {},
      seachName: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    let member = window.localStorage.getItem("member");
    this.member = JSON.parse(member);
    console.log(this.member);
    this.get();
    this.listGet();
  },
  methods: {
    gzClick() {
      this.$post("/web/favorite_save", {
        genre: 7,
        id: Number(this.id),
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.info.is_favorite = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    orderClick(type) {
      this.$router.push({
        path: "/user/order",
        query: {
          type,
        },
      });
    },
    shouhuohandleOk() {
      this.$post("/web/order_shou", {
        id: Number(this.member_lengthData.orderinfo.id),
      }).then((res) => {
        console.log(res);
        this.shouhuovisible = false;
        if (res.data.status) {
          this.$message.success("确认收货成功");
          this.member_lengthGet();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    shouhuoClick() {
      this.shouhuovisible = true;
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/forum_gets", {
        ...this.page,
        member_id: this.id,
      }).then((res) => {
        this.judge = true;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.content = e.content.replace(/<[^>]*>/g, "");
            this.member_forumList.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    scrollEvent() {
      let top = document.querySelector("#ysCont").scrollTop;
      let height = document.querySelector("#ysCont").scrollHeight;
      let offHeight = document.querySelector("#ysCont").offsetHeight;
      if (offHeight + top - height >= 0) {
        this.listGet();
        // 需要执行的代码
      }
    },
    member_lengthGet() {
      this.$get("/web/member_length").then((res) => {
        console.log(res);
        if (res.orderinfo.id != 0) {
          res.orderinfo.express_content = JSON.parse(
            res.orderinfo.express_content
          );
        }
        this.member_lengthData = res;
      });
    },
    address_default_get() {
      this.$get("/web/address_default_get").then((res) => {
        this.addressInfo = res.data;
      });
    },
    get() {
      this.$get("/web/member_detail", {
        member_id: this.id,
      }).then((res) => {
        console.log(res.member);
        this.info = res.member;
        this.member_lengthData = res;
      });
    },
    searchClick() {
      this.page.name = this.seachName;
      this.page.pageNo = 1;
      this.member_forumList = [];
      this.listGet();
    },
    siteJump() {
      window.open("/chuantian/user/addressCont");
    },
    shezhiShowJs() {
      this.szshow = false;
    },
    ltjump(id) {
      this.$router.push({
        path: "/wzCont",
        query: {
          id,
        },
      });
    },
    lxJump(type) {
      this.$router.push({
        path: "/guanzhu",
        query: {
          id: this.id,
          type,
        },
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.contentBody {
  width: 100%;
  height: calc(100vh - 55px);
  overflow: auto;
}
.contentBox {
  overflow: hidden;
  width: 1440px;
  margin: auto;
}
.leftBox {
  width: calc(100% - 360px);
  float: left;
  border-radius: 5px;
  .userHead {
    display: flex;
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 5px;
    .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-sizing: border-box;
    }
    .cont {
      width: calc(100% - 280px);
      height: 100%;
      box-sizing: border-box;
      padding-left: 15px;
      .textBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 16px;
        }
        .txtBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #9c9c9c;
          cursor: pointer;
          .xiugai {
            font-size: 12px;
          }
          .txt {
            position: relative;
            top: -1px;
            margin-left: 2px;
            font-size: 12px;
          }
        }
      }
      .dzBox {
        display: flex;
        align-items: center;
        margin-top: 2px;
        .box {
          display: flex;
          align-items: center;
          margin-right: 15px;
          .iconfont {
            font-size: 18px;
            color: #ee5f00;
          }
          .name {
            position: relative;
            top: -1px;
            color: #666666;
          }
        }
      }
      .jjTxt {
        font-size: 14px;
        margin-top: 2px;
      }
    }
    .dzCont {
      background-color: whitesmoke;
      height: 80px;
      width: 200px;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 13px;
        }
        .iconfont {
          font-size: 12px;
          color: #888;
        }
      }
      .text {
        font-size: 12px;
        text-align: justify;
        span {
          position: relative;
          top: 2px;
          background: rgba(255, 98, 0, 0.102);
          border-radius: 4px;
          box-sizing: border-box;
          color: #ff5000;
          display: inline-block;
          font-family: PingFangSC-Medium;
          font-size: 10px;
          height: 14px;
          line-height: 14px;
          margin-right: 4px;
          text-align: center;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
          vertical-align: top;
          width: 28px;
        }
      }
    }
    .gzBox {
      width: 200px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .box {
        width: 80px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #ee1000;
        cursor: pointer;
      }
      .uns {
        background-color: #c2c2c2;
      }
    }
  }
  .wlBox {
    border-radius: 5px;
    margin-top: 10px;
    background-color: #fff;
    padding: 15px;
    .title {
      color: #11192d;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 24px;
      margin-top: 8px;
    }
    .cont {
      display: flex;
      align-items: center;
      .img {
        width: 96px;
        height: 96px;
        border-radius: 5px;
        overflow: hidden;
      }
      .box {
        width: calc(100% - 196px);
        height: 96px;
        padding-left: 15px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        display: flex;
        padding-right: 15px;
        .name {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
      .anniu {
        align-items: center;
        border: 1px solid #e5e8ec;
        border-radius: 4px;
        box-sizing: border-box;
        color: #11192d;
        cursor: pointer;
        display: flex;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        height: 32px;
        justify-content: center;
        line-height: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        width: 80px;
      }
    }
  }
  .content {
    border-radius: 5px;
    margin-top: 10px;
    background-color: #fff;
    .leftcontHead {
      height: 60px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 37px;
      .boxs {
        display: flex;
        align-items: center;
        line-height: 60px;
      }
      .box {
        margin-right: 44px;
        height: 58px;
        cursor: pointer;

        .name {
          font-size: 16px;
        }
      }

      .on {
        border-bottom: 3px solid #f66;

        // transition: all .3s linear;
        .name {
          font-weight: 700;
        }
      }
      .inpBox {
        width: 220px;
        border-radius: 35px;
        height: 35px;
        border: 1px solid #f66;
        position: relative;
        color: #f66;
        overflow: hidden;
        margin-right: 15px;
        input {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding-left: 15px;
          color: #f66;
        }
        .ssBox {
          top: 0;
          right: 0;
          height: 100%;
          width: 30px;
          line-height: 35px;
          font-size: 18px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
    .contList {
      padding: 0 20px;
      .cont {
        padding: 20px 0 15px;
        border-bottom: 1px solid #ededed;

        .contHead {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 13px;

          .fbBox {
            display: flex;
            align-items: center;
            flex: 1;

            .phoimg {
              width: 40px;
              height: 40px;

              img {
                object-fit: cover;
                border-radius: 50%;
              }
            }

            .name {
              color: #505766;
              margin-left: 10px;
            }

            .txt {
              padding: 0 6px;
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
              margin-right: 8px;
              border: 1px solid #fb9900;
              color: #fb9900;
              border-radius: 2px;
              margin-left: 15px;
            }

            .txtlan {
              color: #7b8aff;
              border: 1px solid #7b8aff;
            }
          }

          .lxBox {
            display: flex;
            align-items: center;

            .box {
              padding: 0 12px;
              line-height: 28px;
              font-weight: 500;
              color: #333;
              text-align: center;
              font-size: 12px;
              border-radius: 14px;
              background: #eff9ff;
              margin-left: 16px;
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;

              .yuan {
                border-radius: 50%;
                height: 20px;
                width: 20px;
                background: linear-gradient(to right, #00abee, #55c6f3);
                font-weight: 600;
                color: #fff;
                font-size: 14px;
                margin-right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .yuanimg {
                border-radius: 50%;
                height: 20px;
                width: 20px;
                font-weight: 600;
                color: #fff;
                font-size: 14px;
                margin-right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .hongb {
              background-color: #ffefef;
            }
          }
        }

        .contTitle {
          font-size: 18px;
          font-weight: 600;
          color: #333;
          line-height: 22px;
          margin-bottom: 12px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            margin-top: 1px;
            width: 36px;
            text-align: center;
            display: inline-block;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            margin-right: 8px;
            color: #fff;
            // background: #ff7a7a;
            background: #fb9900;
            position: relative;
            top: -1px;
            border-radius: 2px;
          }
        }

        .contText {
          color: #666;
          cursor: pointer;
        }

        .funBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #999;
          margin-top: 5px;

          .boxs {
            display: flex;

            .box {
              display: flex;
              align-items: center;
              margin-right: 15px;

              .browse {
                font-size: 18px;
              }

              .message {
                font-size: 16px;
              }

              .praise {
                font-size: 16px;
              }

              .send {
                font-size: 16px;
              }

              .num {
                position: relative;
                top: -1px;
                font-size: 12px;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
}
.ritBox1 {
  float: left;
  width: 360px;
  box-sizing: border-box;
  padding-left: 10px;
  .content {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    .contHead {
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      padding: 0 15px;
      .img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #7b8aff;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        .iconfont {
          font-size: 12px;
        }
      }
      .huang {
        background-color: #ffc67b;
      }
      .lv {
        background-color: #0bb822;
      }
    }
    .cont {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 28px 0 13px;
      .box {
        text-align: center;
        cursor: pointer;
        min-width: 33.33%;
        margin-bottom: 15px;
        .num {
          font-weight: 600;
          font-size: 24px;
        }
        .name {
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
}
</style>
